import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Sidebar from "../pages/sidebar";
import Header from "../component/header/header";
import Inbox from "../pages/inbox";
import Users from "../pages/users";
import AdminUsers from "../pages/admin-users";
import Enquiries from "../pages/enquiries";
import Projects from "../pages/projects";
import Assigned from "../pages/assigned";
import Resolved from "../pages/resolved";
import Pending from "../pages/pending";
import Dashboard from "../pages/dashboard";
import Purchase_History from "../pages/purchase";
import InvoiceOverview from "../pages/invoice-overview";
import Login from "../pages/login";
import ProtectedRoutes from "../component/ProtectedRoutes";
import NotAuthUser from "../pages/not-authorised-user";
import ProjectDetails from "../pages/project_details";
import Paid from "../pages/paid";
import Unpaid from "../pages/unpaid";
import IdVerification from "../pages/id-verification";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Router = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  // Fetch role from storage
  const role = localStorage.getItem("role") || sessionStorage.getItem("role");


  return (
    <div className="flex">
      <ToastContainer />
      {!isLoginPage && <Header />}
      {!isLoginPage && <Sidebar userRole={role} />}
      <div className="flex-grow">
        <Routes>
          {/* Public Route */}
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin-users" element={<AdminUsers />} />

          {/* Protected Routes */}
          <Route element={<ProtectedRoutes allowedRoles={["super-admin", "agent"]} />}>
            <Route path="/users" element={<Users />} />
            {/* <Route path="/inbox" element={<Inbox />} /> */}
            <Route path="/enquiries" element={<Enquiries />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<ProjectDetails />} />
            <Route path="/assigned" element={<Assigned />} />
            <Route path="/resolved" element={<Resolved />} />
            <Route path="/pending" element={<Pending />} />
            <Route path="/paid" element={<Paid />} />
            <Route path="/unpaid" element={<Unpaid />} />
            <Route path="/invoice-overview" element={<InvoiceOverview />} />
            <Route path="/id-verification" element={<IdVerification />} />
            <Route path="/purchase" element={<Purchase_History />} />
          </Route>

          <Route path="/not-authorised-user" element={<NotAuthUser />} />

          {/* Catch-All Route: Redirect unknown paths to login */}
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default Router;
