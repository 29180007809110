import React, { useEffect, useState } from "react";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Loader from "../../component/loader/Loader";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IdVerification = () => {
    const navigate = useNavigate()
    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedData, setSelectedData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState(null);
    const [buttonLoader, setButtonLoader] = useState(false);
    const [buttonLoader2, setButtonLoader2] = useState(false);

    const handleImageClick = (imageUrl) => {
        setFullScreenImage(imageUrl);
    };

    const handleClose = () => {
        setFullScreenImage(null);
    };

    const handleDownload = () => {
        const link = document.createElement("a");
        link.href = fullScreenImage;
        link.target = "_blank";
        link.download = fullScreenImage; // Default file name
        link.click();
    };
    // const [error, setError] = useState(null);

    useEffect(() => {
        // Fetching data from the API when the component mounts
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await fetch("https://wishgeekstechserve.com/ api/get-selected-details", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                console.log(data);

                setUsersData(data.data.idDetails);
            } catch (err) {
                console.log(err);
                setLoading(false)
                // setError(err.message);
            } finally {
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    // Open the modal and set selected data
    const handleView = (data) => {
        setSelectedData(data);
        setIsModalOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedData(null);
    };

    const handleApprove = async (id, name) => {
        setButtonLoader(true);
        try {
            const response = await fetch("https://wishgeekstechserve.com/ api/get-selected-details", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id, isIdVerified: true }),
            });

            console.log(id);


            const result = await response.json();

            if (response.ok) {
                setButtonLoader(false);
                console.log("Update successful:", result);
                toast.success(`Document Approved! 🎉 {${name}}`);
                // Refresh data or notify the user
            } else {
                console.error("Error:", result.error);
            }
        } catch (error) {
            console.error("Request failed:", error);
            toast.error(`Document failed to update status for ${name}`);
        }
    };

    const handleDenied = async (id, name, email) => {
        // const mailType = "send";
        setButtonLoader2(true);
        try {
            const response = await fetch(`https://wishgeekstechserve.com/ api/send-id-verification-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, name, email }),
            });

            if (!response.ok) {
                throw new Error('Failed to send ID verification email');
            }

            const result = await response.json();

            setButtonLoader2(false);
            toast.info(`Document denied for ${name}, a denied email was sent to ${email}`);
        } catch (error) {
            console.error('Error sending ID verification email:', error);
            toast.error(`Document failed to deny for ${name}`);
        }
    };



    return (
        <>
            <div className="container h-[100vh] overflow-y-auto bg-white p-4">
                <div className="flex items-center justify-between mb-4 mt-3 border-b border-gray-300">
                    <h1 className="text-3xl font-medium mt-12 text-gray-500">ID Verification</h1>
                </div>

                {loading ? (
                    <Loader />
                ) : (

                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200  bg-white mb-5">
                            <thead className="bg-[#338dfb] text-white">
                                <tr>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                        Date
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                        Project name
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                        Phone number
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                        Doc Type
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                        Doc Id
                                    </th>
                                    <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                        Action
                                    </th>
                                </tr>
                            </thead>

                            <tbody className="bg-white divide-y divide-gray-200">
                                {usersData.length > 0 ? (
                                    usersData.map((data, index) => (
                                        <tr key={index} className={`${data.isIdVerified ? 'bg-green-100' : 'bg-red-100'}`}>
                                            <td className="px-6 py-4 text-xs  border font-medium">
                                                {new Date(data.createdAt).toLocaleDateString("en-US", {
                                                    year: "2-digit",
                                                    month: "2-digit",
                                                    day: "2-digit",
                                                })}{" "}
                                                <p>{new Date(data.createdAt).toLocaleTimeString("en-US", {
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}</p>
                                            </td>
                                            <td className="px-6 py-4 text-xs  border  font-bold">
                                                {data.projectName}
                                            </td>
                                            <td className="px-6 py-4 text-xs  border  font-medium">
                                                {data.userId.email}
                                            </td>
                                            <td className="px-6 py-4 text-xs  border  font-medium">
                                                {data.userId.phone}
                                            </td>
                                            <td className="px-6 py-4 text-xs  border  font-medium">
                                                {data.idDetails.docsType}
                                            </td>
                                            <td className="px-6 py-4 text-xs  border  font-medium">
                                                {data.idDetails.docsIdNumber}
                                            </td>
                                            <td className="px-6 py-4 text-xs  border  font-medium">
                                                <button className="bg-blue-500 text-white p-2 rounded-lg" onClick={() => handleView(data)}>View</button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="6"
                                            className="px-6 py-4 text-xs text-gray-500 text-center"
                                        >
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div >

            {/* Modal */}
            {isModalOpen && selectedData && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 flex-col">
                    <div className="bg-white p-6 rounded-lg w-[50%] max-h-[90%] relative">
                        {/* Close Button */}
                        <button
                            className="absolute top-2 right-2 text-gray-600"
                            onClick={closeModal}
                        >
                            &#x2715;
                        </button>

                        {/* Modal Content */}
                        <h2 className="text-lg font-bold mb-4">Document Details</h2>
                        <div className="flex items-center justify-between">
                            <p className="mb-2">
                                <strong>Project Name:</strong> {selectedData.projectName}
                            </p>
                            <p className="mb-2">
                                <strong>Email:</strong> {selectedData.userId.email}
                            </p>
                        </div>
                        <div className="flex items-center justify-between">
                            <p className="mb-2">
                                <strong>Phone:</strong> {selectedData.userId.phone}
                            </p>
                            <p className="mb-2">
                                <strong>Doc Type:</strong> {selectedData.idDetails.docsType}
                            </p>
                        </div>
                        <p className="mb-4 text-center">
                            <strong>Doc ID:</strong> {selectedData.idDetails.docsIdNumber}
                        </p>
                        <div className="flex space-x-4">
                            <div className="w-1/2">
                                <h6 className="inline">Front:</h6>
                                <img
                                    src={selectedData.idDetails.frontImageUrl}
                                    alt="Front Image"
                                    className="w-auto h-auto rounded border max-h-[170px] cursor-zoom-in"
                                    onClick={() => handleImageClick(selectedData.idDetails.frontImageUrl)}
                                    title="click to open image"
                                />
                            </div>
                            <div className="w-1/2">
                                <h6 className="inline">Back:</h6>
                                <img
                                    src={selectedData.idDetails.backImageUrl}
                                    alt="Back Image"
                                    className="w-auto h-auto rounded border max-h-[170px] cursor-zoom-in"
                                    onClick={() => handleImageClick(selectedData.idDetails.backImageUrl)}
                                    title="click to open image"
                                />
                            </div>
                        </div>
                        {!selectedData.isIdVerified ? (
                            <div className="flex items-center justify-center space-x-4 mt-4">
                                <button className="bg-green-500 px-4 py-2 text-white rounded-md" onClick={() => handleApprove(selectedData.id, selectedData.userId.firstName)}>
                                    {buttonLoader ? <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div> :
                                        "Approve"
                                    }
                                </button>
                                <button className="bg-red-500 px-4 py-2 text-white rounded-md" onClick={() => handleDenied(selectedData.id, selectedData.userId.firstName, selectedData.userId.email)}>
                                    {buttonLoader2 ? <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div> :
                                        "Deny"
                                    }
                                </button>
                            </div>
                        ) : (
                            <p className="text-center mt-4 text-green-500 font-bold">Verified</p>
                        )}

                    </div>

                </div>
            )}


            {/* Full Screen Modal */}
            {fullScreenImage && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-4">
                    <div className="relative w-full max-w-5xl h-full max-h-screen flex justify-center items-center">
                        <img
                            src={fullScreenImage}
                            alt="Full Screen"
                            className="max-w-full max-h-full object-cover rounded"
                        />
                        <button
                            onClick={handleClose}
                            className="absolute top-4 right-4 text-white bg-red-600 rounded px-4 py-2"
                        >
                            Close
                        </button>
                        <button
                            onClick={handleDownload}
                            className="absolute bottom-4 right-4 text-white bg-blue-600 rounded px-4 py-2"
                        >
                            Download
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default IdVerification;
