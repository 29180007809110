import React from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

const Linechart = (props) => {
  Chart.register(...registerables);

  const chartData = {
    labels: props.data.map((entry) => {
      // Convert the month number to month name, or use month number directly
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
      return months[entry.month]; // Convert month number to name
    }),
    datasets: [
      {
        label: "Enquiry Count",
        data: props.data.map((entry) => entry.count),
        borderColor: "#3159A6",
        backgroundColor: "rgba(49, 89, 166, 0.2)",
        fill: true,
        tension: 0.4,
        pointRadius: 6,
        pointBackgroundColor: "#3159A6",
        pointBorderColor: "#fff",
        pointBorderWidth: 2,
      },
    ],
  };
  

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: "bold",
            color: "black",
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            weight: "bold",
            color: "black",
          },
          stepSize: 20, // Set step size to 20 for the y-axis
          min: 0, // Set the minimum value for the y-axis
          max: Math.max(...props.data.map((entry) => entry.count)) + 20, // Set the maximum value for the y-axis based on the data
        },
      },
    },
  };

  return (
    <div className="w-full mx-auto p-4">
      <div className="relative w-full h-80">
        <h1 className="font-bold text-2xl">Enquiries Over Time</h1>
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default Linechart;
