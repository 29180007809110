import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import Loader from "../../component/loader/Loader";
import CardActions from '@mui/material/CardActions';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import QueryPopupModal from '../../component/project/query/QueryPopupModal';

const ProjectDetails = () => {
    // const location = useLocation()
    const { id } = useParams();
    const [ticketData, setTicketData] = useState({ ticket: {}, queries: [], });
    const [planDetails, setPlanDetails] = useState({});
    const [selectedQueryDetails, setSelectedQueryDetails] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    console.log(ticketData.ticket);
    
    const getPlanDetails = async (data) => {

        try {
            setLoading(true);
            const response = await fetch('https://wishgeekstechserve.com/ api/check-plan-exist', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    ticketId: data._id,
                    userId: data.userId._id

                }),
            });
            if (!response.ok) {
                throw new Error('Failed to check-plan-exist');
            }
            const res = await response.json();
            setPlanDetails(res.PlanDetails);
        } catch (error) {
            console.log(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const ticketResponse = await fetch(`https://wishgeekstechserve.com/ api/ticket?id=${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!ticketResponse.ok) {
                throw new Error("Failed to fetch ticket data");
            }
            const newTicketData = await ticketResponse.json();


            newTicketData && await getPlanDetails(newTicketData.ticket)

            const queryResponse = await fetch('https://wishgeekstechserve.com/ api/get-query-tickets', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ serviceTicketId: id }),
            });

            if (!queryResponse.ok) {
                throw new Error("Failed to fetch query tickets data");
            }
            const queriesData = await queryResponse.json();

            setTicketData({
                ticket: newTicketData.ticket || null,
                queries: queriesData.queries || [],
            });
        } catch (err) {
            console.error('Error fetching data:', err.message);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();

    }, []);

    // console.log(ticketData, planDetails);

    if (loading) {
        return <div className="h-full w-full flex items-center justify-center py-4">
            <Loader />
        </div>;
    }

    const closeModal = () => {
        setSelectedQueryDetails({});
        setIsModalOpen(false);
    }

    return (
        <>
            <div className="container h-[100vh] overflow-y-auto bg-white p-4">
                {isModalOpen && (
                    <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity w-full  ${isModalOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>

                        <QueryPopupModal ticketId={ticketData.ticket._id} userId={ticketData?.ticket?.userId._id} data={selectedQueryDetails} closeModal={closeModal} />
                    </div>
                )}
                <div className="flex items-center justify-between mb-4 mt-24 border-b border-gray-300">

                    <p>Name: {(ticketData?.ticket?.userId?.firstName + " " + ticketData?.ticket?.userId?.lastName) || "Name"}  <Tooltip title={`email: ${ticketData?.ticket?.userId?.email} ||
                        phone: ${ticketData?.ticket?.userId?.phone}
                        `}><InfoIcon fontSize='small' /> </Tooltip></p>
                    <p>Project className: <span className='text-xl font-bold text-blue-600'>{ticketData?.ticket?.pricingEnquiryDetailsId?.projectName} </span></p>
                    <p>Plan: <span className='text-blue-600'>{planDetails && "Active"}</span>  <Tooltip title={`service: ${ticketData?.ticket?.pricingEnquiryDetailsId?.serviceType} ||

                        amount: ${ticketData?.ticket?.pricingEnquiryDetailsId?.totalAmount}
                        `}><InfoIcon fontSize='small' /> </Tooltip></p>
                </div>

                <div className="overflow-x-auto">
                    <div className='w-fit ml-auto'>
                    <Button onClick={()=> setIsModalOpen(true)} variant="contained" size='small' className='mb-2' endIcon={<AddCircleOutlineIcon />}>
                        Raise Query
                    </Button>
                    </div>
                    <table className="min-w-full divide-y divide-gray-200  bg-white mb-5">
                        <thead className="bg-[#338dfb] text-white">
                            <tr>
                                <th className="px-6 py-1 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Issue Title
                                </th>
                                <th className="px-6 py-1 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Plan
                                </th>
                                <th className="px-6 py-1 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Type
                                </th>

                                <th className="px-6 py-1 text-xs font-bold uppercase tracking-wider text-left border">
                                    Query Date
                                </th>
                                <th className="px-6 py-1 text-xs font-bold uppercase tracking-wider text-left border">
                                    Status
                                </th>
                                <th className="px-6 py-1 text-xs font-bold uppercase tracking-wider text-left border">
                                    Edit Details
                                </th>
                            </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                            {ticketData.queries.length > 0 ? (
                                ticketData.queries.map((data, i) => (
                                    <tr key={i}>
                                        <td className="px-6 py-2 text-xs  border  font-medium">
                                            {data.queryDetails}
                                        </td>
                                        <td className="px-6 py-2 text-xs  border  font-medium">
                                            {data && planDetails ? "Active" : "Expired"}
                                        </td>
                                        <td className="px-6 py-2 text-xs  border  font-medium">
                                            {data.queryType}
                                        </td>
                                        <td className="px-6 py-2 text-xs  border  font-medium">
                                            {new Date(data.createdAt).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-2 text-xs  border  font-medium">
                                            {data.status}
                                        </td>
                                        <td className="px-6 py-1 text-xs border font-medium ">
                                            <Button size='small' onClick={() => { setSelectedQueryDetails(data); setIsModalOpen(true) }} variant="outlined" >Edit</Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="6"
                                        className="px-6 py-2 text-xs text-gray-500 text-center"
                                    >
                                        No Query data available for this project
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ProjectDetails