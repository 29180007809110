import React from "react";
import "./index.css";
import { MdDashboardCustomize, MdAssignmentTurnedIn, MdPending } from "react-icons/md";
import { RiInboxUnarchiveFill } from "react-icons/ri";
import { FaUserSecret, FaHistory, FaUser } from "react-icons/fa";
import { MdDomainVerification } from "react-icons/md";
import { TbProgressAlert } from "react-icons/tb";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { IoTicket } from "react-icons/io5";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { SiDavinciresolve } from "react-icons/si";
import { Link, useLocation } from "react-router-dom";
import { PiInvoiceBold } from "react-icons/pi";

const Sidebar = ({ userRole }) => {
  const location = useLocation();

  const tabs = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <MdDashboardCustomize style={{ fontSize: "20px" }} />,
      restrictedRoles: ["agent"],
    },
    // {
    //   name: "Inbox",
    //   path: "/inbox",
    //   icon: <RiInboxUnarchiveFill style={{ fontSize: "20px" }} />,
    //   restrictedRoles: [],
    // },
    {
      name: "Admin Users",
      path: "/admin-users",
      icon: <FaUserSecret style={{ fontSize: "20px" }} />,
      restrictedRoles: ["agent"],
    },
    {
      name: "Users",
      path: "/users",
      icon: <FaUser  style={{ fontSize: "20px" }} />,
      restrictedRoles: [],
    },
    {
      name: "Enquiries",
      path: "/enquiries",
      icon: <TbProgressAlert style={{ fontSize: "20px" }} />,
      restrictedRoles: [],
    },
    {
      name: "Projects",
      path: "/projects",
      icon: <IoTicket style={{ fontSize: "20px" }} />,
      restrictedRoles: [],
    },
    {
      name: "Assigned",
      path: "/assigned",
      icon: <MdAssignmentTurnedIn style={{ fontSize: "20px" }} />,
      restrictedRoles: [],
    },
    {
      name: "Unassigned",
      path: "/pending",
      icon: <MdPending style={{ fontSize: "20px" }} />,
      restrictedRoles: [],
    },
    {
      name: "Resolved",
      path: "/resolved",
      icon: <SiDavinciresolve style={{ fontSize: "20px" }} />,
      restrictedRoles: [],
    },
    {
      name: "Unpaid",
      path: "/unpaid",
      icon: <RequestQuoteIcon style={{ fontSize: "20px" }} />,
      restrictedRoles: [],
    },
    {
      name: "Invoice Overview",
      path: "/invoice-overview",
      icon: <PiInvoiceBold  style={{ fontSize: "20px" }} />,
      restrictedRoles: ["agent"],
    },
    {
      name: "Id Verification",
      path: "/id-verification",
      icon: <MdDomainVerification  style={{ fontSize: "20px" }} />,
      restrictedRoles: ["agent"],
    },
  ];

  // Filter tabs based on restricted roles
  const accessibleTabs = tabs.filter(tab => !tab.restrictedRoles.includes(userRole));

  return (
    <div className="flex flex-col sidebar shadow-lg">
      {/* Sidebar Menu */}
      <ul className="space-y-2 pt-32 lg:pt-28 xl:pt-20 px-4 flex-grow overflow-y-auto">
        {accessibleTabs.map((tab, index) => (
          <li key={index} className="group font-bold text-[15px] text-center">
            <Link
              to={tab.path}
              className={`flex items-center p-2 text-gray-600 transition-colors rounded-lg no-underline ${location.pathname === tab.path
                  ? "bg-blue-500 text-white"
                  : ""
                }`}
            >
              <span className="">{tab.icon}</span>
              <span className="mx-2">{tab.name}</span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
