import React, { useEffect, useState } from "react";

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Loader from "../../component/loader/Loader";
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";

const Projects = () => {
    const navigate = useNavigate()
    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);

    useEffect(() => {
        // Fetching data from the API when the component mounts
        const fetchData = async () => {
            try {
                setLoading(true)
                const response = await fetch("https://wishgeekstechserve.com/ api/get-all-users-all-tickets", {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const data = await response.json();
                console.log(data);

                setUsersData(data.tickets);
            } catch (err) {
                console.log(err);
                setLoading(false)
                // setError(err.message);
            } finally {
                setLoading(false)
            }
        };

        fetchData();
    }, []);

    const handleChangeStatus = () => {

    }

    const handleOpenTicketDetails = (e, id) => {
        navigate(`/projects/${id}`)
    }

    if (loading) {
        return <div className="h-full w-full flex items-center justify-center py-4">
            <Loader />
        </div>;
    }

    // if (error) {
    //     return <div>Error: {error}</div>;
    // }


    return (


        <>
            <div className="container h-[100vh] overflow-y-auto bg-white p-4">
                <div className="flex items-center justify-between mb-4 mt-3 border-b border-gray-300">
                    <h1 className="text-3xl font-medium mt-12 text-gray-500">Projects</h1>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200  bg-white mb-5">
                        <thead className="bg-[#338dfb] text-white">
                            <tr>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Project Name
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Service Type
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                                    Amount
                                </th>

                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    Date
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    Status
                                </th>
                                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                    View
                                </th>
                            </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                            {usersData.length > 0 ? (
                                usersData.map((data) => (
                                    <tr key={data._id}>
                                        <td className="px-6 py-4 text-xs  border  font-bold">
                                            {data.pricingEnquiryDetailsId.projectName}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {data.pricingEnquiryDetailsId.serviceType}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {data.pricingEnquiryDetailsId.totalAmount}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">
                                            {new Date(data.createdAt).toLocaleDateString()}
                                        </td>
                                        <td className="px-6 py-4 text-xs  border  font-medium">

                                            <Select
                                                size="small"
                                                labelId="demo-select-small-label"
                                                id="demo-select-small"
                                                value={data.status}
                                                label="Status"
                                                onChange={handleChangeStatus}
                                            >
                                                <MenuItem value={"Pending"}>Pending</MenuItem>
                                                <MenuItem value={"Completed"}>Completed</MenuItem>
                                            </Select>

                                        </td>
                                        <td className="px-6 py-4 text-xs border font-medium ">
                                            <Button variant="outlined" onClick={(e) => { handleOpenTicketDetails(e, data._id) }}>Details</Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="6"
                                        className="px-6 py-4 text-xs text-gray-500 text-center"
                                    >
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div >
        </>
    );
};

export default Projects;
