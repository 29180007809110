import React, { useEffect, useState } from "react";
import Loader from "../../component/loader/Loader";
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const Assigned = () => {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Fetching data from the API when the component mounts
    const fetchData = async () => {
      try {
        setLoading(true)
        const response = await fetch("https://wishgeekstechserve.com/ api/get-all-assigned-tickets-or-query", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();

        data.assigned && setTableData(data.assigned);
      } catch (err) {
        console.log(err);
        setTableData([])
        setLoading(false)

      } finally {
        setLoading(false)
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div className="h-full w-full flex items-center justify-center py-4">
      <Loader />
    </div>;
  }
  const handleChangeStatus = () => {

  }


  return (
    <>
      <div className="container h-[100vh] overflow-y-auto bg-white p-4">
        <div className="flex items-center justify-between mb-4 mt-3 border-b border-gray-300">
          <h1 className="text-3xl font-medium mt-24 text-gray-500">Assigned</h1>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200  bg-white mb-5">
            <thead className="bg-[#338DFB] text-white">
              <tr>
                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                Project Name
                </th>
                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                  User Email
                </th>
                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                  Assign
                </th>
                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                  Status
                </th>
                <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border cursor-pointer">
                  Preview
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {tableData.length > 0 ? (
                tableData.map((data, i) => (
                  <tr key={i}>
                    <td className="px-6 py-4 text-xs  border  font-medium">
                      {data.Subject}
                    </td>
                    <td className="px-6 py-4 text-xs  border  font-medium">
                      {data.UserEmail}
                    </td>
                    <td className="px-6 py-4 text-xs text-[#3b82f6] border  font-medium">
                      {/* <div
                        className="text-center"
                        // style={{
                        //   border: "1px solid #3b82f6  ",
                        //   borderRadius: "0.5rem",
                        // }}
                      > */}
                      {data.assignee.join(",")}
                      {/* </div> */}
                    </td>
                    <td className="px-6 py-4 text-xs text-[#3b82f6] border  font-medium">

                      <Select
                        size="small"
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={data.status}
                        label="Status"
                        onChange={handleChangeStatus}
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"In Progress"}>In Progress</MenuItem>
                        <MenuItem value={"Completed"}>Completed</MenuItem>
                      </Select>
                    
                    </td>
                    <td className="px-6 py-4 text-xs text-[#3b82f6] border  font-medium">
                      <div
                        className=" px-2 py-2 text-center"
                        style={{
                          border: "1px solid #3b82f6  ",
                          borderRadius: "0.5rem",
                        }}
                      >
                        Preview
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    className="px-6 py-4 text-xs text-gray-500 text-center"
                  >
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Assigned;
