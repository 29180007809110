import React, { useEffect, useState } from "react";
import { getTicketData, ticketData } from "../../assests/constants";
import Barchart from "../../component/chart";
import bannerdigital from "../../assests/image/digital.png";
import { data } from "../../assests/data";
import { createWebSocketConnection } from "../../utils/websocket";
import Linechart from "../../component/chart";

const Dashboard = () => {
  const [enquiryCount, setEnquiryCount] = useState(0);
  const [enquiriesData, setEnquiriesData] = useState([]);

  useEffect(() => {
    // Fetch initial enquiry count
    const fetchInitialCount = async () => {
      try {
        const token = localStorage.getItem("token") || sessionStorage.getItem("token");
        const response = await fetch("https://wishgeekstechserve.com/ api/submit-enquiry", {
          method: "GET",
          headers: {
            "Authorization": `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch initial count");
        }

        const data = await response.json();
        setEnquiryCount(data.enquiries.length);
        setEnquiriesData([{ month: new Date().getMonth(), count: data.enquiries.length }]);
      } catch (error) {
        console.error("Error fetching initial enquiry count:", error);
      }
    };

    // Handle WebSocket messages
    const handleWebSocketMessage = ({ type }) => {
      const currentMonth = new Date().getMonth(); // Get the current month
      const currentYear = new Date().getFullYear(); // Get the current year
    
      if (type === "newEnquiry") {
        setEnquiryCount((prevCount) => prevCount + 1);
        setEnquiriesData((prevData) => [
          ...prevData.filter(entry => entry.month === currentMonth), // Only keep current month data
          { month: currentMonth, year: currentYear, count: prevData.length + 1 },
        ]);
      } else if (type === "deletedEnquiry") {
        setEnquiryCount((prevCount) => Math.max(prevCount - 1, 0));
        setEnquiriesData((prevData) => [
          ...prevData.filter(entry => entry.month === currentMonth), // Only keep current month data
          { month: currentMonth, year: currentYear, count: prevData.length - 1 },
        ]);
      }
    };
    


    // Fetch initial count and set up WebSocket connection
    fetchInitialCount();
    const cleanup = createWebSocketConnection(handleWebSocketMessage);

    return cleanup; // Cleanup WebSocket on unmount
  }, []);

  // Generate the ticket data dynamically
  const ticketData = getTicketData(enquiryCount);

  return (
    <div className="container mx-auto h-[100vh] p-0 overflow-y-auto bg-white">
      <div className="relative h-[200px] md:h-[280px]">
        <img
          src={bannerdigital}
          className="absolute inset-0 object-cover w-full h-full"
          alt="Banner Image"
        />
      </div>

      <div className="container relative mx-auto p-3">
        <div
          className="flex justify-around bg-white py-4 w-[95%] md:w-11/12 mx-auto 
          border shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] rounded-lg relative z-10 
          mt-[-13.5%] md:mt-[-6.5%]"
        >
          {ticketData.map((ticket) => (
            <div key={ticket.id} className="flex items-center space-x-2">
              <img
                src={ticket.imgSrc}
                alt={ticket.title}
                className="w-10 md:w-20 h-20"
              />
              <div className="flex flex-col">
                <p className="text-black font-thin mb-0">{ticket.title}</p>
                <p className="text-black font-bold mb-0">{ticket.value}</p>

                {ticket.vector && (
                  <p className="text-[#3159A6] font-bold flex items-center">
                    <span className="mr-2">
                      <img
                        src={ticket.vector}
                        alt="Vector Icon"
                        className="inline-block w-4 h-4"
                      />
                    </span>
                    {ticket.isPositive ? "" : "-"}
                    {ticket.percentage}
                    <span className="text-black mx-1">{ticket.month}</span>
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="mt-3">
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
          <div className="w-full">
            <Linechart data={enquiriesData} />
          </div>
        </div>
      </div>

      <div className="container relative mx-auto p-3 mt-28 mb-4 ">
        <div
          className="flex justify-around bg-white py-4 w-[95%] md:w-11/12 mx-auto 
          border shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] rounded-lg relative z-10 
          mt-[-13.5%] md:mt-[-6.5%]"
        >
          {ticketData.map((ticket) => (
            <div key={ticket.id} className="flex items-center space-x-2">
              <img
                src={ticket.imgSrc}
                alt={ticket.title}
                className="w-10 md:w-20 h-20"
              />
              <div className="flex flex-col">
                <p className="text-black font-thin mb-0">{ticket.title}</p>
                <p className="text-black font-bold mb-0">{ticket.value}</p>

                {ticket.vector && (
                  <p className="text-[#3159A6] font-bold flex items-center">
                    <span className="mr-2">
                      <img
                        src={ticket.vector}
                        alt="Vector Icon"
                        className="inline-block w-4 h-4"
                      />
                    </span>
                    {ticket.isPositive ? "" : "-"}
                    {ticket.percentage}
                    <span className="text-black mx-1">{ticket.month}</span>
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
